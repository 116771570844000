.form-row {
    width: 310px;
    margin: auto;

}

.booking-tip {
    text-align: center;
    margin-top: 10px;
}

.final-button {
    border-radius: 0 !important;
    width: 310px;
    height: 60px;
    color: white !important;
    background-color: black !important;
    text-transform: uppercase;
}