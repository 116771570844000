.date {
    cursor: pointer;
    width: 40%;
    height: 30%;
    display: inline-block;
    border-radius: 0;
    margin: 2px;
    font-weight: 600;
    &-available {
        background-color: #A9DE99;
    }
    &-unavailable {
        background-color: #BF4E4E;
    }
    &-booked {
        background-color: #b69606;
    }
}

button.react-calendar__tile {
    cursor: default !important;
    height: 100px;
    border-width: 1px;
    background-color: white;
    border-color: lightgrey;
    abbr {
        display: none;
    }
}

.react-calendar__month-view__days__day--weekend {
    background-color: rgb(223, 212, 197) !important;
}

.react-calendar__month-view__weekdays__weekday {
    abbr {
        width: fit-content;
        display: block;
        margin: auto;
    }
}

.popup-icon {
    cursor: pointer;
}

.react-calendar__navigation {
    display: grid;
    padding-left: calc(50% - 200px);
    height: 40px;
    grid-template: 1fr / 50px 50px 200px 50px 50px;

    button {
        border: 0 !important;
    }
}