.navbar {
    background-image: url('background.jpg');
    background-position-y: 50%;
    background-size: cover;
    background-position-x: center;
    height: 530px;
    display: grid;
    grid-template-areas: 
        "..   logo lang"
        "..   ..   desc"
        "..   ..   .."
    ;
    grid-template-rows: 80px 1fr 80px;  
    grid-template-columns: 1fr 210px 1fr;

}

@media (max-width: 767px) {
    .navbar {
        grid-template-areas: 
            "logo"
            "lang"
            "desc"
            ".."
        ;
        grid-template-rows: 80px 30px 1fr 80px;  
        grid-template-columns: 100%;
    }
    .desc p {
        padding-left: 45%;
        padding-right: 5%;
    }
}

.logo {
    grid-area: logo;
    p {
        color: white; 
        font-family: 'my-font'; 
        text-transform: uppercase;
        margin: 8px auto !important; 
        width: 200px; 
        text-align: center;
    }
}
.lang {
    grid-area: lang;
    width: fit-content;
    margin: auto;
    div {
        display: inline-block;
        width: 30px;
        color: white;
        cursor: pointer;
    }
}
.desc {
    grid-area: desc;
    p {
        padding-right: 15%;
    }
}
.wrapper {
  margin: auto;
  width: 201px;
  height: 43px;
  margin-top: 12px;
}

.img-logo {
  width: 100%;
  height: 100%;
}
