.selector__control {
    height: 60px;
    border-radius: 0 !important;
}

.selector__control--is-focused {
    border-color: black !important;
    box-shadow: 0 0 0 1px black !important;
}

.selector__option--is-focused {
    background-color: #9d9d9d !important;
}

.selector__option--is-selected {
    background-color: #4a4a4a !important;
}