//the opened calendar
.app-calendar {
    width: 310px
}
//calendar header
.react-datepicker__month-container {
    width: 308px;   
}
//calendar cell
.react-datepicker__day,
.react-datepicker__day-name {
    width: 42px;
    height: 42px;
    margin: auto;
    :hover {
        border-radius: 0 !important;
    }
}

//hide days of previous or next months
.react-datepicker__day--outside-month {
    color: white !important;
    background-color: white !important;
}

//input
.app-calendar-wrapper {
    div {
        input {
            width: 310px;
            height: 60px;
            border-radius: 0;
            border-width: 1px;
            border-color: lightgrey;
        }
    }
}

//dates
.day--available {
    background-color: #d3ffcd !important;
    color: #000 !important;
    padding-top: 2px;
}

.day--booked {
    color: #000 !important;
    font-weight: 400!important;
    border: 2px solid #ffc4c4 !important;
    background-color: white !important;
}

//disabled dates of selected month
.react-datepicker__day--disabled:not(.react-datepicker__day--outside-month) {
    background-color: #f5f5f5;
    color: #3d3d3d;
    padding-top: 2px;
    cursor: default;
    :hover {
        background-color: #f5f5f5 !important;
        color: #3d3d3d !important;
    }
}

//vertical centering of text inside a cell
.day-cell {
    margin-top: 6px;
}

//font
.react-datepicker * {
    font-weight: 600;
}