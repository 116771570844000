
$color-green: black;
.checkbox {
    width: 318px;
    height: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
    label {
        position: relative;
        min-height: 34px;
        display: block;
        padding-left: 50px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        p {
            white-space: pre-wrap;
            margin: 0 0 0 15px !important;
        }
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            margin: 4px;
            width: 40px;
            height: 40px;
            transition: transform 0.28s ease;
            border: 1px solid $color-green;
        }
        &:after {
            content: "";
            display: block;
            width: 20px;
            height: 10px;
            border-bottom: 2px solid $color-green;
            border-left: 2px solid $color-green;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            position: absolute;
            top: 16px;
            left: 15px;
        }
    }
    input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked ~ label {
            &:before {
                border: 1px solid $color-green;
            }
            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }
        &:focus + label::before {
            outline: 0;
        }
    }
}
