@import './pages/Home.scss';
@import './components/Form/SingleSelect.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "my-font";
  src: url(./assets/AxiformaThin.ttf);
  font-style: normal;
  font-weight: 100;
}

* {
  font-family: 'my-font';
}
